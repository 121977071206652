<template>
  <div :class="[rowClass !== undefined ? rowClass : 'row']">
    <div :class="[colClass !== undefined ? colClass : 'col-12 col-md col-lg-4']" v-for="button in buttons" :key="button.value">
      <button
        class="btn"
        :class="[
           modelValue === button.value ?
            (btnClassActive !== undefined ? btnClassActive : 'btn-type-2'):
            (btnClassDefault !== undefined ? btnClassDefault : 'btn-type-4'),
        btnClass !== undefined ? btnClass : 'w-100 text-center mt-4']"
        v-on:click="btnClick(button.value)"
      >
        <span class="semibold-14">{{ button.name }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchButtons",
  props: {
    buttons: Array,
    colClass: String,
    btnClass: String,
    rowClass: String,
    btnClassActive: String,
    btnClassDefault: String,
    modelValue: String,
  },
  emits: ["update:modelValue"],
  methods: {
    btnClick: function (val) {
      this.$emit("update:modelValue", val);
    },
  },
};
</script>
